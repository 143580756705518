import React from "react";
import { css } from "@emotion/react";

import Layout from "../components/layout";
import { Helmet } from "react-helmet";

const headerText = css`
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.5;
`;

const bodyText = css`
  font-size: 1rem;
  line-height: 2;
`;

const centerBlock = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 400px);
`;

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>404: Not found</title>
    </Helmet>
    <div css={centerBlock}>
      <h1 css={headerText}>NOT FOUND</h1>
      <p css={bodyText}>
        You just hit a route that doesn&#39;t exist... the sadness.
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
